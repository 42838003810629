import store from '@/store'
import router from '@/router'

const GENERAL_HOOK = process.env.VUE_APP_GENERAL_HOOK
const GENERAL_API_HOOK = `${GENERAL_HOOK}/api`
const GENERAL_RECORDS_API_HOOK = `${GENERAL_HOOK}/api/records`
const ALL_SOURCES_URL = `${GENERAL_RECORDS_API_HOOK}/sources/`
const ALL_ARCHIVES_URL = `${GENERAL_RECORDS_API_HOOK}/archives/`
const ALL_FUNDS_URL = `${GENERAL_RECORDS_API_HOOK}/funds/`
const ALL_COLLECTIONS_URL = `${GENERAL_RECORDS_API_HOOK}/collections/`
const ALL_BOOKS_URL = `${GENERAL_RECORDS_API_HOOK}/metric-books/`
const ALL_PAGES_URL = `${GENERAL_RECORDS_API_HOOK}/page-scans/`
const ALL_ALGORITHMS_URL = `${GENERAL_RECORDS_API_HOOK}/algorithms/`

const SENDING_BOOKS_TO_TOLOKA_URL = `${ALL_BOOKS_URL}send_to_toloka/`
const SENDING_BOOKS_TO_PROCESSING_URL = `${ALL_BOOKS_URL}send_to_processing/`

function GET_SOURCE_URL(source_id) {
  return `${ALL_SOURCES_URL}${source_id}/`
}

function GET_ARCHIVE_URL(archive_id) {
  return `${ALL_ARCHIVES_URL}${archive_id}/`
}

function GET_FUND_URL(fund_id) {
  return `${ALL_FUNDS_URL}${fund_id}/`
}

function GET_COLLECTION_URL(col_id) {
  return `${ALL_COLLECTIONS_URL}${col_id}/`
}

function GET_BOOK_URL(book_id) {
  return `${ALL_BOOKS_URL}${book_id}/`
}

function GET_PAGE_URL(page_id) {
  return `${ALL_PAGES_URL}${page_id}/`
}

function GET_PAGE_ACTION_URL(page_id, action) {
  return `${GET_PAGE_URL(page_id)}${action}/`
}

const SPELLING_CORRECTION_URL = `${GENERAL_RECORDS_API_HOOK}/spelling_correction/`
const ENTITY_RECOGNITION_URL = `${GENERAL_RECORDS_API_HOOK}/entity_recognition/`
const SCANS_UPLOADING_URL = `${GENERAL_RECORDS_API_HOOK}/scans_uploading/`
const RECORDS_SEARCH_URL = `${GENERAL_RECORDS_API_HOOK}/records_search/`
const RECORDS_TAGS_SEARCH_URL = `${GENERAL_RECORDS_API_HOOK}/tags_search/`

const GENERAL_API_PROCESSING_URL = `${GENERAL_API_HOOK}/processing`
const MONITORING_URL = `${GENERAL_API_PROCESSING_URL}/monitoring/`
const MONITORING_QUEUES_URL = `${GENERAL_API_PROCESSING_URL}/message-queues/`
const MONITORING_INSTANCE_GROUPS_URL = `${GENERAL_API_PROCESSING_URL}/instance-groups/`
const MONITORING_PARSERS_URL = `${GENERAL_API_PROCESSING_URL}/parser-processes/`
const MONITORING_PARSER_LOGS_URL = `${GENERAL_API_PROCESSING_URL}/parser-logs/`

const GENERAL_API_TOLOKA_URL = `${GENERAL_API_HOOK}/toloka`
const TOLOKA_OPERATIONS_URL = `${GENERAL_API_TOLOKA_URL}/operations/`

function GET_TOLOKA_OPERATION_URL(operation_id) {
  return `${TOLOKA_OPERATIONS_URL}${operation_id}`
}

function GET_MONITORING_PARSER_URL(parser_id) {
  return `${MONITORING_PARSERS_URL}${parser_id}`
}

function GET_INSTANCE_GROUP_URL(group_id) {
  return `${MONITORING_INSTANCE_GROUPS_URL}${group_id}/`
}

const GENERAL_ACCOUNT_HOOK = `${GENERAL_HOOK}/auth`
const AUTH_GET_TOKEN_API = `${GENERAL_ACCOUNT_HOOK}/token/`
const AUTH_REFRESH_TOKEN_API = `${GENERAL_ACCOUNT_HOOK}/token/refresh/`
const AUTH_BLACKLIST_TOKEN_API = `${GENERAL_ACCOUNT_HOOK}/token/blacklist/`

const GENERAL_API_TREES_URL = `${GENERAL_API_HOOK}/gen_trees`
const GEN_CARDS_URL = `${GENERAL_API_TREES_URL}/search_genealogical_cards/`
const GEN_TREES_GET_TREE_URL = `${GENERAL_API_TREES_URL}/get_genealogical_tree/`

const GENERAL_API_HUMAN_BANK_URL = `${GENERAL_API_HOOK}/human_bank`
const HUMAN_BANK_URL = `${GENERAL_API_HUMAN_BANK_URL}/search_human_bank/`

async function fetch_api_json(
  link,
  method = 'GET',
  body_data = null,
  init = null,
) {
  if (!init) {
    init = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: await store.dispatch('getAuthHeader'),
      },
      mode: 'cors',
    }
    if (body_data) {
      init.body = JSON.stringify(body_data)
    }
  }
  let res = await fetch(link, init)
  if (res.status === 401) {
    // Not authenticated
    window.location.href = router.resolve({ name: 'Logout' }).href
    window.location.reload()
    return null
  } else if (res.status === 404 && process.env.VUE_APP_REDIRECT_ON_ERROR) {
    // Page not found
    window.location.href = router.resolve({ name: 'Page404' }).href
    window.location.reload()
  } else if (res.status === 403) {
    // Doesn't have permission
    return null
  }
  return res
}

async function fetch_auth_json(
  link,
  method = 'POST',
  body_data = null,
  init = null,
) {
  if (!init) {
    init = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    }
    if (body_data) {
      init.body = JSON.stringify(body_data)
    }
  }
  return await fetch(link, init)
}

export {
  fetch_api_json,
  fetch_auth_json,
  ALL_SOURCES_URL,
  ALL_ARCHIVES_URL,
  ALL_FUNDS_URL,
  ALL_COLLECTIONS_URL,
  ALL_BOOKS_URL,
  ALL_PAGES_URL,
  ALL_ALGORITHMS_URL,
  GET_SOURCE_URL,
  GET_ARCHIVE_URL,
  GET_FUND_URL,
  GET_COLLECTION_URL,
  GET_BOOK_URL,
  GET_PAGE_URL,
  GET_PAGE_ACTION_URL,
  SPELLING_CORRECTION_URL,
  ENTITY_RECOGNITION_URL,
  SCANS_UPLOADING_URL,
  RECORDS_SEARCH_URL,
  RECORDS_TAGS_SEARCH_URL,
  SENDING_BOOKS_TO_TOLOKA_URL,
  SENDING_BOOKS_TO_PROCESSING_URL,
  MONITORING_URL,
  MONITORING_QUEUES_URL,
  MONITORING_INSTANCE_GROUPS_URL,
  MONITORING_PARSERS_URL,
  MONITORING_PARSER_LOGS_URL,
  TOLOKA_OPERATIONS_URL,
  GET_TOLOKA_OPERATION_URL,
  GET_INSTANCE_GROUP_URL,
  GET_MONITORING_PARSER_URL,
  AUTH_GET_TOKEN_API,
  AUTH_REFRESH_TOKEN_API,
  AUTH_BLACKLIST_TOKEN_API,
  GEN_CARDS_URL,
  GEN_TREES_GET_TREE_URL,
  HUMAN_BANK_URL,
}
