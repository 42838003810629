<script>
import {
  fetch_api_json,
  GET_ARCHIVE_URL,
  GET_BOOK_URL,
  GET_COLLECTION_URL,
  GET_FUND_URL,
  GET_PAGE_URL,
} from '@/common/api_endpoints'
import router from '@/router'

export default {
  name: 'MBBBreadcrumbMixin',
  methods: {
    async get_mb_browser_data_and_breadcumb(
      id,
      active,
      get_url_function,
      router_name,
      display_name_key,
    ) {
      const res = await fetch_api_json(get_url_function(id))
      const data = await res.json()
      return [
        data,
        {
          active: active,
          name: data[display_name_key],
          path: router.resolve({
            name: router_name,
            params: { id: data.id },
          }).href,
        },
      ]
    },
    async get_archive_data_and_breadcumb(archive_id, active) {
      return await this.get_mb_browser_data_and_breadcumb(
        archive_id,
        active,
        GET_ARCHIVE_URL,
        'Фонды архива',
        'short_name',
      )
    },
    async get_fund_data_and_breadcumb(fund_id, active) {
      return await this.get_mb_browser_data_and_breadcumb(
        fund_id,
        active,
        GET_FUND_URL,
        'Описи фонда',
        'number',
      )
    },
    async get_collection_data_and_breadcumb(col_id, active) {
      return await this.get_mb_browser_data_and_breadcumb(
        col_id,
        active,
        GET_COLLECTION_URL,
        'Книги описи',
        'number',
      )
    },
    async get_book_data_and_breadcumb(book_id, active) {
      return await this.get_mb_browser_data_and_breadcumb(
        book_id,
        active,
        GET_BOOK_URL,
        'Страницы книги',
        'number',
      )
    },
    async get_page_data_and_breadcumb(page_id, active) {
      return await this.get_mb_browser_data_and_breadcumb(
        page_id,
        active,
        GET_PAGE_URL,
        'Просмотр сканов',
        'number',
      )
    },
    async get_all_archive_breadcrumbs(archive_id) {
      const res = await this.get_archive_data_and_breadcumb(archive_id, true)
      return [res[1]]
    },
    async get_all_fund_breadcrumbs(fund_id) {
      const [fund_data, fund_breadcrumb] =
        await this.get_fund_data_and_breadcumb(fund_id, true)
      const archive_res = await this.get_archive_data_and_breadcumb(
        fund_data.archive,
        false,
      )
      return [archive_res[1], fund_breadcrumb]
    },
    async get_all_collection_breadcrumbs(col_id) {
      const [col_data, col_breadcrumb] =
        await this.get_collection_data_and_breadcumb(col_id, true)
      const fund_res = await this.get_fund_data_and_breadcumb(
        col_data.fund,
        false,
      )
      const archive_res = await this.get_archive_data_and_breadcumb(
        col_data.archive,
        false,
      )
      return [archive_res[1], fund_res[1], col_breadcrumb]
    },
    async get_all_book_breadcrumbs(book_id) {
      const [book_data, book_breadcrumb] =
        await this.get_book_data_and_breadcumb(book_id, true)
      const col_res = await this.get_collection_data_and_breadcumb(
        book_data.collection,
        false,
      )
      const fund_res = await this.get_fund_data_and_breadcumb(
        book_data.fund,
        false,
      )
      const archive_res = await this.get_archive_data_and_breadcumb(
        book_data.archive,
        false,
      )
      return [archive_res[1], fund_res[1], col_res[1], book_breadcrumb]
    },
    async get_all_page_breadcrumbs(page_id) {
      const [page_data, page_breadcrumb] =
        await this.get_page_data_and_breadcumb(page_id, true)

      const [book_data, book_breadcrumb] =
        await this.get_book_data_and_breadcumb(page_data.book, false)

      const col_res = await this.get_collection_data_and_breadcumb(
        book_data.collection,
        false,
      )
      const fund_res = await this.get_fund_data_and_breadcumb(
        book_data.fund,
        false,
      )
      const archive_res = await this.get_archive_data_and_breadcumb(
        book_data.archive,
        false,
      )
      return [
        archive_res[1],
        fund_res[1],
        col_res[1],
        book_breadcrumb,
        page_breadcrumb,
      ]
    },
    async define_breadcrumbs_for_page(router_name, object_id) {
      switch (router_name) {
        case 'Фонды архива':
          return await this.get_all_archive_breadcrumbs(object_id)
        case 'Описи фонда':
          return await this.get_all_fund_breadcrumbs(object_id)
        case 'Книги описи':
          return await this.get_all_collection_breadcrumbs(object_id)
        case 'Страницы книги':
          return await this.get_all_book_breadcrumbs(object_id)
        case 'Просмотр сканов':
          return await this.get_all_page_breadcrumbs(object_id)
      }
    },
  },
}
</script>
