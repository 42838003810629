<template>
  <CCol>
    <CBreadcrumb class="d-md-down-none me-auto mb-0">
      <CBreadcrumbItem
        v-for="item in breadcrumbs"
        :key="item"
        :href="item.active ? '' : item.path"
        :active="item.active"
      >
        {{ item.name }}
      </CBreadcrumbItem>
    </CBreadcrumb>
  </CCol>
  <CCol>
    <CDropdown class="float-end">
      <CDropdownToggle color="light">{{
        default_algorithm_display_name
      }}</CDropdownToggle>
      <CDropdownMenu>
        <CDropdownItem
          v-for="item in concat_algorithms"
          :key="item.id"
          @click="change_algorithm(item.name, item.display_name)"
        >
          <CLink
            v-c-tooltip="item.name"
            style="color: inherit; text-decoration: inherit"
            >{{ item.display_name ? item.display_name : item.name }}</CLink
          >
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  </CCol>
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'
import MBBBreadcrumbMixin from '@/views/metric_books_browser/mixins/MBBBreadcrumbMixin'
import APIMixin from '@/common/mixins/APIMixin.vue'
import { ALL_ALGORITHMS_URL } from '@/common/api_endpoints'

export default {
  name: 'AppBreadcrumb',
  mixins: [APIMixin],
  data() {
    return {
      api_first_page_link: ALL_ALGORITHMS_URL,
      default_algorithm_display_name: '',
    }
  },
  computed: {
    concat_algorithms() {
      return this.original_api_data
        ? this.original_api_data.filter((item) => item.stage === 'concat')
        : null
    },
  },
  methods: {
    change_algorithm(new_algorithm, new_algorithm_display_name) {
      this.$store.commit('setAlgorithm', {
        tr_algorithm: new_algorithm,
        tr_algorithm_display_name: new_algorithm_display_name,
      })
      window.location.reload()
    },
  },
  setup() {
    const breadcrumbs = ref()

    const getBreadcrumbs = async () => {
      const matched = router.currentRoute.value.matched

      if (
        matched[1].name === 'MetricBook browser' &&
        router.currentRoute.value.params.id
      ) {
        let mb_mixin = MBBBreadcrumbMixin
        const browsable_breadcrumbs =
          await mb_mixin.methods.define_breadcrumbs_for_page(
            router.currentRoute.value.name,
            router.currentRoute.value.params.id,
          )
        // We need metric book browser navigation
        return [
          {
            active: false,
            name: 'MetricBook browser',
            path: '#',
          },
          ...browsable_breadcrumbs,
        ]
      } else {
        return matched
          .filter((route) => route.meta.excludeBreadCrumb !== true)
          .map((route) => {
            return {
              active: route.path === router.currentRoute.value.fullPath,
              name: route.name,
              path: `${router.options.history.base}${route.path}`,
            }
          })
      }
    }

    router.afterEach(async () => {
      breadcrumbs.value = await getBreadcrumbs()
    })

    onMounted(async () => {
      breadcrumbs.value = await getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
  async mounted() {
    let d = await this.$store.dispatch('getAlgorithm')
    this.default_algorithm_display_name = d[1]
    console.log(d)
  },
}
</script>
