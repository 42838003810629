import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import CardLayout from '@/layouts/CardLayout'
import EmptyLayout from '@/layouts/EmptyLayout'

const routes = [
  {
    path: '/',
    name: '',
    component: DefaultLayout,
    redirect: '/browse',
    children: [
      {
        path: '/browse',
        name: 'MetricBook browser',
        redirect: '/browse/archives/',
        component: CardLayout,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'archives/',
            name: 'Архивы',
            component: () => import('@/views/metric_books_browser/Archives'),
          },
          {
            path: 'funds/:id',
            name: 'Фонды архива',
            component: () => import('@/views/metric_books_browser/Funds'),
          },
          {
            path: 'funds/',
            name: 'Фонды',
            component: () => import('@/views/metric_books_browser/Funds'),
          },
          {
            path: 'collections/:id',
            name: 'Описи фонда',
            component: () => import('@/views/metric_books_browser/Collections'),
          },
          {
            path: 'collections/',
            name: 'Описи',
            component: () => import('@/views/metric_books_browser/Collections'),
          },
          {
            path: 'books/:id',
            name: 'Книги описи',
            component: () => import('@/views/metric_books_browser/Books'),
          },
          {
            path: 'books/',
            name: 'Книги',
            component: () => import('@/views/metric_books_browser/Books'),
          },
          {
            path: 'pages/:id',
            name: 'Страницы книги',
            component: () => import('@/views/metric_books_browser/Pages'),
          },
          {
            path: 'scan/:id',
            name: 'Просмотр сканов',
            component: () => import('@/views/metric_books_browser/ScanViewer'),
          },
          {
            path: 'sources',
            name: 'Источники',
            component: () => import('@/views/metric_books_browser/Sources'),
          },
        ],
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        redirect: '/dashboard/monitoring/',
        component: EmptyLayout,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'Dashboard Card Layout',
            redirect: '/dashboard/monitoring/',
            component: CardLayout,
            meta: {
              excludeBreadCrumb: true,
            },
            children: [
              {
                path: 'text-search/',
                name: 'Поиск записей',
                component: () => import('@/views/dashboard/MBRecordSearch'),
              },
              {
                path: 'monitoring/',
                name: 'Статистика обработки',
                component: () =>
                  import('@/views/dashboard/monitoring/MonitoringStats'),
              },
            ],
          },
          {
            path: 'management/',
            name: 'Управление обработкой',
            component: () =>
              import('@/views/dashboard/management/ManagementDashboard'),
          },
          {
            path: 'parsers/',
            name: 'Парсеры',
            component: () =>
              import('@/views/dashboard/management/ParsersDashboard'),
          },
        ],
      },
      {
        path: 'toloka',
        name: 'Толока',
        redirect: '/toloka/operations/',
        component: EmptyLayout,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'operations/',
            name: 'Операции',
            component: () => import('@/views/toloka/Operations'),
          },
        ],
      },
      {
        path: 'genealogical_trees',
        name: 'Генеалогические деревья',
        redirect: '/genealogical_trees/search/',
        component: CardLayout,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'search/',
            name: 'Поиск карточек',
            component: () =>
              import('@/views/gen_trees/GenealogicalCardsSearch'),
          },
        ],
      },
      {
        path: 'human_bank',
        name: 'Human Bank',
        redirect: '/human_bank/search/',
        component: CardLayout,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'search/',
            name: 'Поиск людей',
            component: () => import('@/views/human_bank/HBCardsSearch'),
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: 'login',
        name: 'Login',
        meta: {
          requiresNotAuth: true,
        },
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'logout',
        name: 'Logout',
        component: () => import('@/views/pages/Logout'),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/pages/404',
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
