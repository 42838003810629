export default [
  {
    component: 'CNavTitle',
    name: 'Метрический браузер',
  },
  {
    component: 'CNavItem',
    name: 'Список архивов',
    to: '/browse/archives',
    icon: 'cil-book',
  },
  {
    component: 'CNavItem',
    name: 'Список источников',
    to: '/browse/sources',
    icon: 'cil-double-quote-sans-left',
  },
  {
    component: 'CNavGroup',
    name: 'Глобальный поиск',
    icon: 'cil-search',
    items: [
      {
        component: 'CNavItem',
        name: 'По фондам',
        to: '/browse/funds/',
      },
      {
        component: 'CNavItem',
        name: 'По описям',
        to: '/browse/collections/',
      },
      {
        component: 'CNavItem',
        name: 'По ед. хранения',
        to: '/browse/books/',
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'Управление',
  },
  {
    component: 'CNavItem',
    name: 'Статистика обработки',
    to: '/dashboard/monitoring/',
    icon: 'cil-chart-line',
  },
  {
    component: 'CNavItem',
    name: 'Обработка',
    to: '/dashboard/management/',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Парсеры',
    to: '/dashboard/parsers/',
    icon: 'cil-terminal',
  },
  {
    component: 'CNavTitle',
    name: 'Толока',
  },
  {
    component: 'CNavItem',
    name: 'Выгрузка',
    to: '/toloka/operations/',
    icon: 'cil-pen-alt',
  },
  {
    component: 'CNavTitle',
    name: 'Генеалогия',
  },
  {
    component: 'CNavItem',
    name: 'Поиск по карточкам',
    to: '/genealogical_trees/search/',
    icon: 'cil-address-book',
  },
  {
    component: 'CNavItem',
    name: 'Поиск по архивам',
    to: '/dashboard/text-search/',
    icon: 'cil-text-size',
  },
  {
    component: 'CNavItem',
    name: 'Human Bank',
    to: '/human_bank/search/',
    icon: 'cil-text-size',
  },
]
